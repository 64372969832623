import { StrictMode, lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { kcContext as kcAccountThemeContext } from 'account/KcContext';
import { kcContext as kcLoginThemeContext } from 'login/KcContext';

const AccountTheme = lazy(() => import('account/AccountTheme'));
const LoginTheme = lazy(() => import('login/LoginTheme'));

const rootContainer = document.getElementById('root');
if (!rootContainer) throw new Error('Failed to find the root element.');

createRoot(rootContainer).render(
    <StrictMode>
        <Suspense>
            {(() => {
                if (kcAccountThemeContext !== undefined) {
                    return <AccountTheme kcContext={kcAccountThemeContext} />;
                }

                if (kcLoginThemeContext !== undefined) {
                    return <LoginTheme kcContext={kcLoginThemeContext} />;
                }

                throw new Error(
                    'This application is a Keycloak theme. ' +
                        'It is not meant to be deployed outside of Keycloak.',
                );
            })()}
        </Suspense>
    </StrictMode>,
);
