import { createGetKcContext } from 'keycloakify/login';

export type KcContextExtension =
    | { pageId: 'error.ftl' }
    | { pageId: 'idp-review-user-profile.ftl' }
    | { pageId: 'info.ftl' }
    | { pageId: 'login.ftl' }
    | { pageId: 'login-config-totp.ftl' }
    | { pageId: 'login-idp-link-confirm.ftl' }
    | { pageId: 'login-idp-link-email.ftl' }
    | { pageId: 'login-otp.ftl' }
    | { pageId: 'login-page-expired.ftl' }
    | { pageId: 'login-reset-password.ftl' }
    | { pageId: 'login-update-password.ftl' }
    | { pageId: 'login-update-profile.ftl' }
    | { pageId: 'login-verify-email.ftl' }
    | { pageId: 'logout-confirm.ftl' }
    | { pageId: 'register.ftl' }
    | { pageId: 'saml-post-form.ftl' };

export const { getKcContext } = createGetKcContext<KcContextExtension>({
    mockData: [
        {
            pageId: 'error.ftl',
            message: {
                type: 'error',
                summary: 'Action expired. Please login again.',
            },
        },
        {
            pageId: 'idp-review-user-profile.ftl',
            profile: {
                attributes: [
                    {
                        name: 'username',
                        required: true,
                        value: 'example@example.com',
                        readOnly: false,
                        validators: {},
                        annotations: {},
                        groupAnnotations: {},
                        autocomplete: 'username',
                    },
                ],
            },
        },
        {
            pageId: 'info.ftl',
            messageHeader: 'Perform the following action(s)',
            message: {
                type: 'info',
                summary: 'Perform the following action(s)',
            },
            requiredActions: ['UPDATE_PASSWORD', 'UPDATE_PROFILE', 'VERIFY_EMAIL'],
        },
        {
            pageId: 'login.ftl',
            locale: {
                currentLanguageTag: 'en',
            },
            social: {
                providers: [
                    {
                        alias: 'dataguard',
                        displayName: 'DataGuard',
                        providerId: 'oidc',
                        loginUrl: 'https://auth.dataguard.de/',
                    },
                ],
            },
        },
        {
            pageId: 'login-config-totp.ftl',
            totp: {
                totpSecretEncoded: 'GFHX E32W MQ4U 6N3V IMYG QMTP HFVG UY3G',
                totpSecretQrCode:
                    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAD2AQAAAADNaUdlAAACbUlEQVR4Xu2XO47kMAwFlenKyqhM' +
                    'V1YwgLaKnmDsxW7WxAQWut02S8HD48fqdv67vtozcl8vf0bu6+XPyH29/Bm5r9/BZ2t9jtH6WTvm' +
                    '6OssQ2V88SG2Zt+jxdljhKE6PvuaxHa7fvPbaznqwvvJtoFF1Xy3QXomiQpzU8vND+yqkEF6MlTG' +
                    'U9B9/VW/n+TXytY41Ma5vHHVcFKSuQnzs8NK2XpVxTdNiTyGg2nZPUfEjjI+LUeu+gLtW6Wjjn8/' +
                    'UKAKtFZ3Bqr47rtnf3QvXodFUsZ5NwQ7HM587YylWXW8WaBOZ4YDFYpNWBRlnM5E3zHGZ+uP4sq4' +
                    'mcl8LH0ZfepTbinjYX0YZki15pZUWMOzNoOZRJWenMzbHq3jW4E7C8MzCh59J6iGU5q8l/HE3phu' +
                    '8maVcaTZIwuj6Ey3WC51nPvlS4mzET1Cs+ZvlHF7Y5kT9e2wS/J0UsXNR186pDnqJJCvihrOc1gW' +
                    'SMMqizVPi2XclNiQKELgckrHz/78NGckhS5Zlc5nRHKjwCKOOGdimBuzZH2aniKeyhgJeSpALO54' +
                    'YijjZgh5CmwWynVQiDJufpZjyquvKPbezqcf5szH5YxkI+bAUdcFRfx8z4SeG4+t6pis4yflGcca' +
                    'tvlnYdZxToZ4REEY9GREi3pgq+KIS09sEl+OefPDn0/z3EPIU4mTwerQsCqurHzMwZgvKl6XUcaX' +
                    '8uhRSsNS9Y+CxVLHZ7YF/jSVkqPmvC7l2xGFM/iUV7VWcqLdllxXavZ1QiribgkypDHDTmFUC4o4' +
                    'Uf2wM6eVynMouYr/e738Gbmvlz8j9/XyZ+S+fj3/A8mOa1AhCImiAAAAAElFTkSuQmCC',
                manualUrl: '#',
                qrUrl: '#',
                totpSecret: 'G4nsI8lQagRMUchH8jEG',
                otpCredentials: [],
                supportedApplications: ['totpAppMicrosoftAuthenticatorName'],
                policy: {
                    algorithm: 'HmacSHA1',
                    digits: 6,
                    lookAheadWindow: 1,
                    type: 'totp',
                    period: 30,
                },
            },
        },
        {
            pageId: 'login-idp-link-confirm.ftl',
            message: {
                type: 'error',
                summary:
                    'User with email example@example.com already exists. ' +
                    'How do you want to continue?',
            },
        },
        {
            pageId: 'login-idp-link-email.ftl',
            idpAlias: 'DataGuard',
            brokerContext: {
                username: 'example@example.com',
            },
        },
        {
            pageId: 'login-otp.ftl',
        },
        {
            pageId: 'login-page-expired.ftl',
        },
        {
            pageId: 'login-reset-password.ftl',
        },
        {
            pageId: 'login-update-password.ftl',
            message: {
                type: 'warning',
                summary: 'You need to change your password.',
            },
        },
        {
            pageId: 'login-update-profile.ftl',
            user: {
                editUsernameAllowed: true,
                username: 'example@example.com',
                email: 'example@example.com',
                firstName: 'Example',
                lastName: undefined,
            },
        },
        {
            pageId: 'login-verify-email.ftl',
            message: {
                type: 'warning',
                summary: 'You need to verify your email address to activate your account.',
            },
            user: {
                email: 'example@example.com',
            },
        },
        {
            pageId: 'logout-confirm.ftl',
        },
        {
            pageId: 'register.ftl',
            realm: {
                registrationEmailAsUsername: true,
            },
        },
        {
            pageId: 'saml-post-form.ftl',
        },
    ],
});

export const { kcContext } = getKcContext({
    mockPageId: 'login.ftl',
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>;
