import { createGetKcContext } from 'keycloakify/account';

export type KcContextExtension =
    | { pageId: 'account.ftl' }
    | { pageId: 'password.ftl' }
    | { pageId: 'federatedIdentity.ftl' }
    | { pageId: 'sessions.ftl' }
    | { pageId: 'totp.ftl' };

const commonMock = {
    account: {
        username: 'mmustermann@dataguard.de',
        email: 'mmustermann@dataguard.de',
        firstName: 'Max',
        lastName: 'Mustermann',
        attributes: {
            roles: JSON.stringify({
                '3': ['customer'],
            }),
        },
    },
};

export const { getKcContext } = createGetKcContext<KcContextExtension>({
    mockData: [
        {
            ...commonMock,
            pageId: 'account.ftl',
            realm: {
                registrationEmailAsUsername: false,
                editUsernameAllowed: false,
            },
        },
        {
            ...commonMock,
            pageId: 'password.ftl',
        },
        {
            ...commonMock,
            pageId: 'federatedIdentity.ftl',
        },
        {
            ...commonMock,
            pageId: 'sessions.ftl',
        },
        {
            ...commonMock,
            pageId: 'totp.ftl',
        },
    ],
});

export const { kcContext } = getKcContext({
    mockPageId: 'account.ftl',
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>;
